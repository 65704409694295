import React from 'react';

const Header = () => {
  return (
    
    <header id="site_header" className="header mobile-menu-hide">
      <div className="inner-header">
        <ul className="main-menu">
              <li>
                <a href="#about-me" className="nav-anim">
                  <img src="img/kami.webp" alt="" width={20} />
                  <span className="link-text">About Me</span>
                </a>
              </li>
              <li>
                <a href="#experience" className="nav-anim">
                  <img src="img/certificate.svg" alt="" width={20} />
                  <span className="link-text">Experience</span>
                </a>
              </li>
              <li>
                <a href="#portfolio" className="nav-anim">
                  <img src="img/devices.svg" alt="" width={20} />
                  <span className="link-text">Projects</span>
                </a>
              </li>              
        </ul>
        <div className="fixed-sec">
        <div className="header-content">
            <div className="header-photo">
              <img src="img/main_photo.webp" alt="Kamran Iftikhar" />
            </div>
            <div className="header-titles">
              <h2>Syed Zahid Hussain</h2>
              <h4>Full Stack Developer</h4>
            </div>
          </div>
          
          
          <div className="header-buttons">
            <a href="#" target="_blank" className="btn btn-primary">
              <i className="fab fa-github" /> Visit My GitHub
            </a> 
            
          </div>
          <div className="copyrights">© 2024 All rights reserved.</div> 

        </div>
      </div>        
    </header>
  );
};

export default Header;