import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import "./css/bootstrap-grid.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';


function App() {
  return (
    <div className="page">
      <div className="page-content">
        <Header />
        <div className="content-area">
            {/* About Me Subpage */}
            <section className="about-section" id="about-me">
              <div className="section-content">
                {/* Personal Information */}
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <h3><span className="head-sla">//</span> Biography</h3>
                    <p>As a senior software engineer at TechSurge, I specialize in full-stack development with expertise in MEAN/MERN stacks and Python. My work focuses on delivering scalable, efficient solutions that meet both technical and business needs. In addition to coding, I actively engage in client communication, ensuring clear alignment between project requirements and outcomes. With a strong foundation in software engineering, frontend/backend development, and problem-solving, I have a proven track record of building user-friendly interfaces and robust backend systems.</p>
                    <a href="#" className="hire-btn">
                      Hire Me!
                    </a>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <h3><span className="head-sla">//</span> Personal Details</h3>
                    <div className="info-list">
                      <ul>
                        
                        <li>
                          <span className="title">Residence.....</span>
                          <span className="value">Karachi, Pakistan</span>
                        </li>
                        <li>
                          <span className="title">
                            Experience in Years.....
                          </span>
                          <span className="value">19 Years</span>
                        </li>
                        <li>
                          <span className="title">Working Status.....</span>
                          <span className="value">Available</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* End of Personal Information */}
              </div>
            </section>
            {/* End of About Me Subpage */}

            <div className="section-content" id="experience">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h3><span className="head-sla">//</span> Experience</h3>
                  </div>
                  <div className="timeline timeline-second-style clearfix">
                    <div className="timeline-item clearfix">
                      <div className="left-part">
                        <h5 className="item-period">2008 – Present </h5>
                        <span className="item-company">Techsurge</span>
                      </div>
                      <div className="divider" />
                      <div className="right-part">
                        <h4 className="item-title">Software Engineer</h4>
                        <p>I work as a senior software engineer with expertise in MEAN/MERN stacks and Python, delivering comprehensive full-stack solutions and scalable backend systems. I also manage client communication, ensuring project alignment and successful delivery of technical.</p>
                      </div>
                    </div>
                    <div className="timeline-item clearfix">
                      <div className="left-part">
                        <h5 className="item-period">2006 - 2008</h5>
                        <span className="item-company">Centric Source</span>
                      </div>
                      <div className="divider" />
                      <div className="right-part">
                        <h4 className="item-title">Developer</h4>
                        <p>
                        I worked as a backend developer using PHP, building robust server-side applications and managing databases. On the frontend, I developed dynamic interfaces with HTML, CSS, and JavaScript, ensuring responsive and user-friendly experiences.
                        </p>
                      </div>
                    </div>
                    <div className="timeline-item clearfix">
                      <div className="left-part">
                        <h5 className="item-period">2005 - 2006</h5>
                        <span className="item-company">Right Solutions</span>
                      </div>
                      <div className="divider" />
                      <div className="right-part">
                        <h4 className="item-title">Frontend Developer</h4>
                        <p>
                        As a startup, I began focusing on frontend development, honing my skills in building engaging user interfaces and delivering seamless user experiences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Skills & Certificates */}
                <div className="col-xs-12 col-sm-6">
                  {/* Design Skills */}
                  <div className="block-title">
                    <h3><span className="head-sla">//</span> Programming Skills</h3>
                  </div>
                  <div className="skills-info skills-second-style">
                    {/* Skill 1 */}
                    <div className="skill clearfix">
                      <h4>HTML/CSS</h4>
                      <div className="skill-value">95%</div>
                    </div>
                    <div className="skill-container skill-1">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 1 */}
                    {/* Skill 2 */}
                    <div className="skill clearfix">
                      <h4>JavaScript</h4>
                      <div className="skill-value">65%</div>
                    </div>
                    <div className="skill-container skill-2">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 2 */}
                    {/* Skill 3 */}
                    <div className="skill clearfix">
                      <h4>Node.js</h4>
                      <div className="skill-value">80%</div>
                    </div>
                    <div className="skill-container skill-3">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 3 */}
                    {/* Skill 4 */}
                    <div className="skill clearfix">
                      <h4>Express.js</h4>
                      <div className="skill-value">90%</div>
                    </div>
                    <div className="skill-container skill-4">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 4 */}
                    {/* Skill 5 */}
                    <div className="skill clearfix">
                      <h4>Database Management</h4>
                      <div className="skill-value">95%</div>
                    </div>
                    <div className="skill-container skill-5">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 5 */}
                    {/* Skill 6 */}
                    <div className="skill clearfix">
                      <h4>RESTful APIs</h4>
                      <div className="skill-value">85%</div>
                    </div>
                    <div className="skill-container skill-6">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 6 */}
                    {/* Skill 7 */}
                    <div className="skill clearfix">
                      <h4>Version Control/Git</h4>
                      <div className="skill-value">100%</div>
                    </div>
                    <div className="skill-container skill-7">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 7 */}
                    {/* Skill 8 */}
                    <div className="skill clearfix">
                      <h4>DevOps </h4>
                      <div className="skill-value">75%</div>
                    </div>
                    <div className="skill-container skill-8">
                      <div className="skill-percentage" />
                    </div>
                    {/* End of Skill 8 */}
                  </div>
                </div>
              </div>
            </div>

            <div className="section-content" id="portfolio">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="block-title">
                    <h3><span className="head-sla">//</span> Projects</h3>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/chaajao.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Chaajao</h3> <span>Web & Mobile App (Native: IOS, Android)</span>
                            </div>
                            <p>Developed a comprehensive test preparation app featuring over 300,000+ questions, video lectures, live classes, and learning analytics. The app supports various exam categories, providing students with an all-inclusive platform for exam readiness.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon5.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon6.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon7.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/aws.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/boonio.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Boonio</h3> <span>Webapp</span>
                            </div>
                            <p>Built a versatile platform for managing service transactions, including RFP management, escrow payments, and team communication. The app supports business operations and collaboration.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon7.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/a.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/body.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Bodygraph</h3> <span>Webapp</span>
                            </div>
                            <p>Developed an app for Human Design practitioners, offering advanced charting, HD reports, and WooCommerce integration. The app provides specialized tools for practitioners to enhance their services.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon7.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/auto.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Automobile</h3> <span>Mobile App (Native: IOS, Android)</span>
                            </div>
                            <p>Created a customer-focused app for an automobile company, offering product listings, test drive scheduling, maintenance booking, and dealer information. The app enhances customer interaction and streamlines service management.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sap.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon6.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon7.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/ds.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Dsight</h3> <span>Mobile App (Native: IOS, Android)</span>
                            </div>
                            <p>Built an app for offline sales teams with features like doctor listings, messaging, task management, and performance metrics. The app optimizes sales processes and improves team productivity.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon5.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon6.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/mh.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Medical Helpdesk</h3> <span>Mobile App (Native: IOS, Android)</span>
                            </div>
                            <p>Developed a COVID-19 helpdesk app for employee tracking and support, featuring check-ins, location tracking, insurance claims, and health reports. The app ensures employee safety and compliance during the pandemic.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon5.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon6.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/icy.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>i challenge you</h3> <span>Mobile App (Native: IOS, Android)</span>
                            </div>
                            <p>Created a social challenge app with features for challenge creation, rewards, and integrated payment gateways. The app promotes community engagement and user interaction through collaborative tasks.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/lv.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/php.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon5.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon6.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block">
                            <div className="prject-image">
                                <img src="img/ugai.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Ugai</h3> <span>Mobile App (Hybrid: Flutter)</span>
                            </div>
                            <p>Designed a management platform for agricultural businesses, enabling farmer management, order processing, and regional service activation. The app streamlines large-scale agricultural operations.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/ag.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/c.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div> 

                    <div className="col-xs-12 col-sm-4">
                        <div className="projrct-block ">
                            <div className="prject-image">
                                <img src="img/eaccess.webp" className="w-100" alt="Kamran Iftikhar" />
                            </div>
                            <div className="project-heading">
                                <h3>Eaccess</h3> <span>Mobile App (Native: IOS, Android)</span>
                            </div>
                            <p>Developed a mobile app for secure access to CDC accounts, featuring portfolio management, IPO tracking, and a consolidated dashboard. The app enhances user experience and investment management.</p>
                            <div className="project-icon">
                                <p>Tech Stack:</p>
                                <ul>
                                    <li><img src="img/tech-icon/icon1.png" className=" w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon2.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/sql.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon4.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon5.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/icon6.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/l.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/d.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                    <li><img src="img/tech-icon/o.png" className="w-100" alt="Kamran Iftikhar" /></li>
                                </ul>
                            </div>                    
                        </div>                    
                    </div>            
                </div>
                  
                </div>            
              </div>
            </div>


            <div className="section-content">
                {/* Clients */}
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                    <div className="block-title">
                        <h3>
                        <span className="head-sla">//</span> Hobbies
                        </h3>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-4">
                    <div className="fun-fact gray-default">
                        <img src="img/1.webp" className="w-100" alt="Kamran Iftikhar" />
                        <h4>Snooker</h4>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                    <div className="fun-fact gray-default">
                        <img src="img/2.webp" className="w-100" alt="Kamran Iftikhar" />
                        <h4>Robots</h4>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 ">
                    <div className="fun-fact gray-default">
                        <img src="img/3.webp" className="w-100" alt="Kamran Iftikhar" />
                        <h4>Travelling</h4>
                    </div>
                    </div>
                </div>
                {/* End of Fun Facts */}
                </div>

            
        </div>
      </div>
    </div>
  );
}

export default App;
